<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useMetaStore } from '~/stores/meta.store';
import ListProjects from './list.vue';
import ShowProject from './show.vue';
import { useProjectsStore } from '~/stores/projects.store';

const route = useRoute();
const metaStore = useMetaStore();
const projectsStore = useProjectsStore();

const data: any = computed(() => {
	const data = projectsStore.projects;
	if (!data) {
		showError({
			statusCode: 404,
			statusMessage: "Page Not Found"
		})
  	}
	return data
});

metaStore.metaGetter();

await projectsStore.fetchProjects(route);
</script>

<template>
	<template v-if="data?.data && data?.model === 'ListProjects'">
		<ListProjects :data="data" />
	</template>
	<template v-else-if="data?._id && data?.model === 'ShowProject'">
		<ShowProject :data="data" />
	</template>
</template>
